<template>
  <div class="users grid grid-view-layout">
    <page-header
      :hide-product-dropdown="true"
    />
    <h1>404 page not found</h1>
  </div>
</template>

<script>
import PageHeader from '@/components/domain/page-header.vue';

export default {
  components: {
    PageHeader,
  },

  setup() {
    return {
      PageHeader,
    };
  },
};
</script>
